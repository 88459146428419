import logo from './logo.svg';
import './App.css';
import { Wave } from 'react-animated-text';
import cat from './images/personaje.png'


function App() {
  return (
    <div className="main-container">
      
      <header className="App-header">
        <Wave text="Coming Soon" effect="stretch" effectChange={2.0} />
        {/* <img className="cat" src={cat} alt="Logo" /> */}
        {/* <p>
          Coming Soon
        </p> */}
      </header>
    </div>
  );
}

export default App;
